import React, { useState, useEffect, useRef } from "react";
import { Rnd } from "react-rnd";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import {
  Title as TitleIcon,
  TextFields as TextFieldsIcon,
  Image as ImageIcon,
  VideoLibrary as VideoLibraryIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Link as LinkIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Delete as DeleteIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "./../../store/action/index";
import HeaderComponent from "./HeaderComponent";
import { json } from "d3";
import ImageSlider from "./ImageSlider/ImageSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const useStyles = makeStyles((theme) => ({
  editorContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "10px",
    boxSizing: "border-box",
  },
  toolbarContainer: {
    width: "100%",
    backgroundColor: "#2c3e50",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  toolbarOptions: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  toolbarButton: {
    color: "white",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#1abc9c",
    },
  },
  toolbarButtonActive: {
    color: "#1abc9c",
  },
  editorArea: {
    flexGrow: 1,
    padding: "20px",
    backgroundColor: "#f5f5f5",
    overflowY: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "960px",
    boxSizing: "border-box",
    margin: "0 auto",
    minHeight: "150vh",
  },
  selectedElement: {
    border: "2px solid #00bcd4",
    borderRadius: "4px",
    padding: "5px",
    boxSizing: "border-box",
  },
  previewArea: {
    flexGrow: 1,
    padding: "0",
    backgroundColor: "#ecf0f1",
    overflow: "hidden",
    width: "100%",
    maxWidth: "960px",
    boxSizing: "border-box",
    margin: "0 auto",
    minHeight: "150vh",
  },
  iframePreview: {
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "white",
    display: "block",
    minHeight: "150vh",
  },
  exportButtonContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  exportButton: {
    backgroundColor: "#1abc9c",
    color: "white",
    "&:hover": {
      backgroundColor: "#16a085",
    },
  },
  colorPicker: {
    padding: "5px",
    borderRadius: "4px",
    backgroundColor: "white",
    border: "1px solid #ccc",
  },
  headerContainer: {
    width: "100%",
    maxWidth: "960px",
    backgroundColor: "#1e2b3c",
    color: "white",
    zIndex: 1000,
    padding: "20px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: "60px",
    height: "60px",
    backgroundColor: "#1abc9c",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
  },
  avatarInitials: {
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
  },
  businessInfo: {
    display: "flex",
    flexDirection: "column",
  },
  businessName: {
    fontSize: "28px",
    fontWeight: "bold",
    margin: 0,
  },
  introSection: {
    marginTop: "40px",
    color: "white",
    backgroundColor: "#2c3e50",
    padding: "20px",
  },
  introHeading: {
    fontSize: "26px",
    letterSpacing: "4px",
    fontWeight: "bold",
  },
  introText: {
    fontSize: "18px",
    lineHeight: "1.8",
    marginTop: "20px",
    color: "#ccc",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch", // Stretch both table and library to the same height
    gap: "20px",
    marginTop: "40px",
    width: "100%",
  },
  categoriesGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", // Two columns layout for four items
    gap: "20px", // Space between the squares
    flex: 1, // Take up available space
  },
  categoryItem: {
    backgroundColor: "#1e2b3c",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px",
    borderRadius: "8px",
    minHeight: "200px", // Fixed height for the squares
    textAlign: "center",
  },
  categoryTable: {
    width: "100%",
    backgroundColor: "#1e2b3c",
    color: "white",
    borderSpacing: "0",
    "& th, td": {
      padding: "10px",
      borderBottom: "1px solid #3c4a5c",
    },
    "& th": {
      fontWeight: "bold",
    },
  },
  librarySection: {
    flex: "0 0 300px", // Fixed width for the library
    backgroundColor: "#1e2b3c",
    color: "white",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  libraryHeading: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  documentList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    flexGrow: 1, // Ensure the list stretches with the container
    overflowY: "auto", // Scroll if content overflows
  },
  documentItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
    borderBottom: "1px solid #3c4a5c",
  },
  documentIcon: {
    width: "30px",
    height: "30px",
    backgroundColor: "#3498db",
    borderRadius: "4px",
    marginRight: "10px",
  },
  documentDetails: {
    display: "flex",
    flexDirection: "column",
  },
  documentName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  documentDate: {
    fontSize: "14px",
    color: "#aaa",
  },
}));

const ELEMENT_TYPES = {
  HEADING: "h1",
  TEXT: "p",
  IMAGE: "img",
  VIDEO: "video",
  DOCUMENT: "document",
  LINK: "a",
  BULLET_LIST: "ul",
  NUMBERED_LIST: "ol",
};

const TemplateMakerPage = ({
  UploadDocs,
  notify,
  rowData,
  createStockTemplate,
  getStockTemplate,
  stockTemplate,
  updateStockTemplate,
  getStock,
  getStockCategories,
  stocks,
  stockCategories,
  getStockOrderBook,
  stockOrderBook,
  getStockDoc,
  stockDocs,
}) => {
  const classes = useStyles();
  const [elements, setElements] = useState([]);
  const [selectedElementId, setSelectedElementId] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#ecf0f1");
  const [uploading, setUploading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [images, setImages] = useState([]);

  // State for header content
  const [headerContent, setHeaderContent] = useState({
    businessName: "Business Name",
    businessIntroTitle: "BUSINESS INTRODUCTION",
    businessIntroText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    businessLogoSrc: "https://placehold.co/90",
  });

  useEffect(() => {
    if (stocks && stocks.length > 0) {
      const stock = stocks[0]; // Assuming you're using the first stock from the array
      setHeaderContent((prevContent) => ({
        ...prevContent,
        businessName: stock.stockName || "Business Name", // Update businessName with stockName
        // businessIntroText: `Total Quantity: ${stock.totalQuantity}, Assigned Quantity: ${stock.assignedQuantity}`,
        businessLogoSrc: `${process.env.REACT_APP_SPACE_LINK}/${stock.stockLogo}`,
        totalQuantity: `${stock.totalQuantity}`,
        assignedQuantity: `${stock.assignedQuantity}`,
        stockPrice: `${stock.stockPrice}`, // You can customize the intro text as needed
      }));
    }
  }, [stocks]); // This effect will run when stocks data is updated

  useEffect(() => {
    if (rowData?.stockId) {
      const data = {
        stockId: rowData.stockId, // Send stockId from rowData
      };
      getStock(data); // Call getStock only when rowData.stockId is available
    }
  }, [rowData, getStock]);
  useEffect(() => {
    if (rowData?.stockId) {
      const data = {
        stockId: rowData.stockId, // Send stockId from rowData
      };
      getStockOrderBook(data); // Call getStock only when rowData.stockId is available
    }
  }, [rowData, getStock]);

  useEffect(() => {
    if (rowData?.stockId) {
      const data = {
        stockId: rowData.stockId, // Send stockId from rowData
      };
      getStockDoc(data); // Call getStock only when rowData.stockId is available
    }
  }, [rowData, getStock]);

  useEffect(() => {
    if (rowData?.stockId) {
      const data = {
        stockId: rowData.stockId, // Send stockId from rowData
      };
      getStockCategories(data); // Call getStock only when rowData.stockId is available
    }
  }, [rowData, getStockCategories]);
  useEffect(() => {
    if (stockCategories) {
      console.log("Stock Category Data:", stockCategories); // Log the stock data to the console
    }
  }, [stockCategories]);
  useEffect(() => {
    if (stockOrderBook) {
      console.log("Stock order book:", stockOrderBook); // Log the stock data to the console
    }
  }, [stockDocs]);
  useEffect(() => {
    if (stockOrderBook) {
      console.log("Stock Docs:", stockDocs); // Log the stock data to the console
    }
  }, [stockDocs]);

  useEffect(() => {
    if (stocks) {
      console.log("Stock Data:", stocks); // Log the stock data to the console
    }
  }, [stocks]);

  useEffect(() => {
    if (rowData?.stockId) {
      const data = {
        id: "",
        stockId: rowData.stockId,
        createdBy: "",
        lastUpdatedBy: "",
        type: null,
        isArchived: false,
      };
      getStockTemplate(data);
    }
  }, [rowData, getStockTemplate]);

  useEffect(() => {
    if (stockTemplate) {
      console.log("Stock Template Data:", stockTemplate);

      if (
        Array.isArray(stockTemplate) &&
        stockTemplate.length > 0 &&
        stockTemplate[0].template
      ) {
        const template = stockTemplate[0].template;
        console.log("Template received:", template); // Debug log
        // Only show the template in preview if not updating
        if (!isUpdating) {
          setTabValue(1); // Switch to Preview Mode
        }
      } else {
        console.log("No template received or template is empty.");
      }
    }
  }, [stockTemplate, isUpdating]);

  const handleHeaderContentChange = (key, value) => {
    setHeaderContent((prevContent) => ({
      ...prevContent,
      [key]: value,
    }));
  };

  const handleUpdateTemplate = () => {
    setIsUpdating(true);
    setTabValue(0); // Switch to Edit Mode
  };

  const handleCreateTemplate = () => {
    if (elements.length > 0) {
      createStockTemplate({
        stockId: rowData.stockId,
        template: generateHtmlContent(),
      }).then(() => {
        // Refresh the page after successful creation
        window.location.reload();
      });
    }
  };

  const generateId = () => "_" + Math.random().toString(36).substr(2, 9);

  const getSelectedElement = () =>
    elements.find((element) => element.id === selectedElementId);

  const handleStyleChange = (key, value) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? { ...element, styles: { ...element.styles, [key]: value } }
          : element
      )
    );
  };
  const handleImageUpload = async (file) => {
    if (file) {
      try {
        const path = "stockTemplates/sliderImages";
        const res = await UploadDocs(file, path);
        if (res.data.status) {
          const uploadedDocUrl = res.data.data?.uploaded_docs_url[0];
          const fullUrl = `${process.env.REACT_APP_SPACE_LINK}/${uploadedDocUrl}`;
          setImages((prevImages) => [...prevImages, fullUrl]);
          notify("Image uploaded successfully", "success");
        } else {
          notify(res.data.errorMessage, "error");
        }
      } catch (err) {
        notify("Error uploading image", "error");
      }
    }
  };

  const handleDeleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    notify("Image deleted successfully", "success");
  };
  const handleAddElement = (type) => {
    switch (type) {
      case ELEMENT_TYPES.IMAGE:
      case ELEMENT_TYPES.VIDEO:
      case ELEMENT_TYPES.DOCUMENT: {
        const choice = window.prompt(
          `Enter '1' to upload a ${type} from your device or '2' to provide a ${type} URL:`
        );

        if (choice === "1") {
          const input = document.createElement("input");
          input.type = "file";
          input.accept =
            type === ELEMENT_TYPES.VIDEO
              ? "video/*"
              : type === ELEMENT_TYPES.DOCUMENT
              ? "application/*"
              : "image/*";
          input.onchange = async (e) => {
            const file = e.target.files[0];
            uploadMedia(type, file);
          };
          input.click();
        } else if (choice === "2") {
          const fileUrl = window.prompt(`Please enter the ${type} URL:`);
          if (fileUrl) {
            addElementToEditor(type, fileUrl);
          } else {
            notify(`${type} URL cannot be empty`, "warning");
          }
        } else {
          notify("Invalid choice. Please enter '1' or '2'.", "warning");
        }
        break;
      }
      case ELEMENT_TYPES.BULLET_LIST:
      case ELEMENT_TYPES.NUMBERED_LIST:
        addElementToEditor(type, "", ["Item 1"]);
        break;
      case ELEMENT_TYPES.LINK:
        const linkUrl = window.prompt("Please enter the link URL:");
        const linkText = window.prompt("Please enter the link text:");
        if (linkUrl && linkText) {
          addElementToEditor(type, linkUrl, [], linkText);
        } else {
          notify("Link URL and text cannot be empty", "warning");
        }
        break;
      default:
        addElementToEditor(type);
    }
  };

  const uploadMedia = (type, file) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    if (file.size > MAX_FILE_SIZE) {
      notify("File size should be less than 5MB", "error");
      return;
    }

    const path = "stockTemplates";
    setUploading(true);

    UploadDocs(file, path)
      .then((res) => {
        const { status, errorMessage, data } = res.data;
        if (status) {
          const uploadedDocUrl = data?.uploaded_docs_url[0];
          const fullUrl = `${process.env.REACT_APP_SPACE_LINK}/${uploadedDocUrl}`;
          addElementToEditor(type, fullUrl);
          notify("File uploaded successfully", "success");
        } else {
          notify(errorMessage, "error");
        }
      })
      .catch((err) => {
        let errorMessage =
          err?.response?.data?.error?.errorMessage ||
          err?.response?.data?.error;
        notify(
          typeof errorMessage === "string"
            ? errorMessage
            : "Error while uploading file!!!",
          "error"
        );
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const addElementToEditor = (type, url = "", items = [], text = "") => {
    const newElement = {
      id: generateId(),
      type,
      content: {
        text,
        url,
        items,
      },
      styles: {
        fontSize: "16px",
        color: "#000000",
        textAlign: "left",
        width: "200px",
        height: "auto",
        position: "absolute",
        backgroundColor: "transparent",
        borderRadius: "0px",
        border: "none",
        fontWeight: "normal",
        fontStyle: "normal",
        textDecoration: "none",
      },
      position: { x: 10, y: elements.length * 60 + 10 },
    };
    setElements((prevElements) => [...prevElements, newElement]);
    setSelectedElementId(newElement.id);
  };

  const handleDragResize = (id, position, size) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id
          ? {
              ...element,
              position,
              styles: {
                ...element.styles,
                width: size.width,
                height: size.height,
              },
            }
          : element
      )
    );
  };

  const handleSelectElement = (id) => setSelectedElementId(id);

  const handleElementChange = (key, value) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? { ...element, content: { ...element.content, [key]: value } }
          : element
      )
    );
  };

  const handleListChange = (index, value) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? {
              ...element,
              content: {
                ...element.content,
                items: element.content.items.map((item, i) =>
                  i === index ? value : item
                ),
              },
            }
          : element
      )
    );
  };

  const handleAddListItem = () => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === selectedElementId
          ? {
              ...element,
              content: {
                ...element.content,
                items: [
                  ...element.content.items,
                  `Item ${element.content.items.length + 1}`,
                ],
              },
            }
          : element
      )
    );
  };

  const handleDeleteElement = () => {
    setElements((prevElements) =>
      prevElements.filter((element) => element.id !== selectedElementId)
    );
    setSelectedElementId(null);
  };

  const renderElement = (element) => {
    const ElementTag = element.type === "p" ? "div" : element.type;

    return (
      <Rnd
        key={element.id}
        size={{ width: element.styles.width, height: element.styles.height }}
        position={element.position}
        onDragStop={(e, d) =>
          handleDragResize(
            element.id,
            { x: d.x, y: d.y },
            { width: element.styles.width, height: element.styles.height }
          )
        }
        onResizeStop={(e, direction, ref, delta, position) =>
          handleDragResize(element.id, position, {
            width: ref.style.width,
            height: ref.style.height,
          })
        }
        bounds="parent"
        className={
          element.id === selectedElementId ? classes.selectedElement : ""
        }
        style={{
          ...element.styles,
          border: element.id === selectedElementId ? "2px solid #3498db" : "",
          borderRadius: "4px",
        }}
        onClick={() => handleSelectElement(element.id)}
      >
        {element.type === "img" ? (
          <img
            src={element.content.url}
            alt="Added element"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: element.styles.borderRadius,
              border: element.styles.border,
            }}
          />
        ) : element.type === "video" ? (
          <video controls style={{ width: "100%", height: "100%" }}>
            <source src={element.content.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : element.type === "document" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              textAlign: element.styles.textAlign,
            }}
          >
            <InsertDriveFileIcon
              onClick={() => window.open(element.content.url, "_blank")}
              style={{
                marginRight: "8px",
                color: "#2962ff",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                padding: "4px",
                backgroundColor: "#ffffff",
                cursor: "pointer",
              }}
            />
            <span
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => handleElementChange("name", e.target.innerText)}
              style={{
                color: "#2962ff",
                textDecoration: "underline",
                cursor: "text",
              }}
            >
              {element.content.name || "Document"}
            </span>
          </div>
        ) : element.type === ELEMENT_TYPES.BULLET_LIST ? (
          <ul
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              listStyleType: "disc",
              margin: "0",
            }}
          >
            {element.content.items.map((item, index) => (
              <li
                key={index}
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleListChange(index, e.target.innerText)}
              >
                {item}
              </li>
            ))}
            <li
              contentEditable={false}
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={handleAddListItem}
            >
              + Add Item
            </li>
          </ul>
        ) : element.type === ELEMENT_TYPES.NUMBERED_LIST ? (
          <ol
            style={{
              width: "100%",
              height: "100%",
              padding: "5px",
              listStyleType: "decimal",
              margin: "0",
            }}
          >
            {element.content.items.map((item, index) => (
              <li
                key={index}
                contentEditable
                suppressContentEditableWarning
                onBlur={(e) => handleListChange(index, e.target.innerText)}
              >
                {item}
              </li>
            ))}
            <li
              contentEditable={false}
              style={{ color: "#3498db", cursor: "pointer" }}
              onClick={handleAddListItem}
            >
              + Add Item
            </li>
          </ol>
        ) : element.type === ELEMENT_TYPES.LINK ? (
          <a
            href={element.content.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: element.styles.color,
              textDecoration: element.styles.textDecoration,
              fontSize: element.styles.fontSize,
              fontWeight: element.styles.fontWeight,
              fontStyle: element.styles.fontStyle,
            }}
          >
            {element.content.text}
          </a>
        ) : (
          <ElementTag
            contentEditable
            suppressContentEditableWarning
            style={{
              width: "100%",
              height: "100%",
              outline: "none",
              padding: "5px",
              boxSizing: "border-box",
              whiteSpace: "pre-wrap", // Preserve new lines
            }}
            onBlur={(e) => handleElementChange("text", e.target.innerText)}
            dangerouslySetInnerHTML={{
              __html: element.content.text.replace(/\n/g, "<br />"), // Replace newlines with <br>
            }}
          ></ElementTag>
        )}
      </Rnd>
    );
  };

  const generateHtmlContent = () => {
    console.log("Stocks Data: ", stocks);

    // Ensure stocks is valid and extract stock prices directly from it
    const stockPrices = stocks?.[0]?.stockPrice || [];

    // Debugging stockPrices to ensure it's processed correctly
    console.log("Processed Stock Prices from Stocks: ", stockPrices);

    // Set the fixed height for the library section
    const tableHeight = "300px"; // You can adjust this height as necessary based on the layout
    const getDocumentSvg = (docName) => {
      const extension = docName.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return `
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#9e9e9e">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>`;
        case "doc":
        case "docx":
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#2962ff">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>`;
        case "xls":
        case "xlsx":
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#4caf50">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M16 2H8c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 15h-2v-2h-2v2H9v-3h2v-2H9v-3h2v2h2v-2h2v3h-2v2h2v3zm0-10H9V5h6v2z"/>
            </svg>`;
        default:
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#9e9e9e">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>`;
      }
    };

    const headerHtml = `
    <div style="width: 100%; max-width: 960px; background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; margin: 0 auto; display: flex; flex-direction: column; border-radius: 12px; backdrop-filter: blur(10px); box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); border: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">
      <div style="display: flex; align-items: center;">
        <div style="width: 90px; height: 90px; background-color: rgba(255, 255, 255, 0.25); border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-right: 20px; border: 2px solid rgba(255, 255, 255, 0.2); backdrop-filter: blur(10px);">
          <img src="${
            headerContent.businessLogoSrc
          }" alt="Logo" style="width: 60px; height: 60px; border-radius: 50%;"/>
        </div>
        <div>
          <h1 style="font-size: 28px; font-weight: bold; margin: 0;">${
            headerContent.businessName
          }</h1>
        </div>
      </div>

      <div style="margin-top: 20px; background-color: rgba(255, 255, 255, 0.1); padding: 20px; border-radius: 12px; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2);">
        <h2 style="font-size: 24px; font-weight: bold; text-align: center;">${
          headerContent.businessIntroTitle
        }</h2>
        <p style="font-size: 18px; line-height: 1.6; margin-top: 15px; color: #eee;">${
          headerContent.businessIntroText
        }</p>
      </div>

      <!-- Flex container for Stock Prices, Categories, Order Book, and Library -->
      <div style="display: flex; flex-direction: row; gap: 20px; margin-top: 30px;">
        <!-- Tables Grid -->
        <div style="flex: 1; display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;">
          <!-- First Table: Stock Prices -->
          <div style="background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; text-align: center; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
            <table style="width: 100%; color: white; border-spacing: 0;">
              <thead>
                <tr>
                  <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Currency Name</th>
                  <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Current Price</th>
                </tr>
              </thead>
              <tbody>
                ${stockPrices
                  .map(
                    (price) => `
                  <tr>
                    <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">${price.currencyName}</td>
                    <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">€ ${price.currentPrice}</td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>

          <!-- Categories Table -->
          <div style="background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; text-align: center; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
            <table style="width: 100%; color: white; border-spacing: 0;">
              <thead>
                <tr>
                  <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Categories</th>
                </tr>
              </thead>
              <tbody>
                ${stockCategories
                  ?.map(
                    (category) => `
                  <tr>
                    <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">${category.name}</td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>

          <!-- Order Book Table with Four Values -->
          <div style="background-color: rgba(255, 255, 255, 0.1); padding: 20px; border-radius: 12px; text-align: center; box-sizing: border-box;">
            <table style="width: 100%; background-color: rgba(255, 255, 255, 0.1); color: white; border-spacing: 0; box-sizing: border-box;">
              <thead>
                <tr>
                  <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;" colspan="2">Order Book</th>
                </tr>
              </thead>
              <tbody>
                <!-- Buy Orders Section -->
                <tr>
                  <td style="font-weight: bold; padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;" colspan="2">Buy Orders</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Quantity:</td>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">${
                    stockOrderBook?.[0]?.buyQuantity || 0
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Value:</td>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">€ ${
                    stockOrderBook?.[0]?.buyValue?.toFixed(2) || 0
                  }</td>
                </tr>

                <!-- Sell Orders Section -->
                <tr>
                  <td style="font-weight: bold; padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;" colspan="2">Sell Orders</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Quantity:</td>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">${
                    stockOrderBook?.[0]?.sellQuantity || 0
                  }</td>
                </tr>
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Value:</td>
                  <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">€ ${
                    stockOrderBook?.[0]?.sellValue?.toFixed(2) || 0
                  }</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Quantity Information Table -->
          <div style="background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; text-align: center; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
            <table style="width: 100%; color: white; border-spacing: 0;">
              <tbody>
                <tr><td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Total Quantity: ${
                  stocks?.[0]?.totalQuantity || 0
                }</td></tr>
                <tr><td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Assigned Quantity: ${
                  stocks?.[0]?.assignedQuantity || 0
                }</td></tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Library Section with Fixed Height and Scroll -->
    <div style="flex: 0 0 300px; background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); height: ${tableHeight}px; overflow-y: auto;overflow-y: auto">
      <h3 style="font-size: 22px; font-weight: bold; margin-bottom: 10px;">Library</h3>
<ul style="list-style-type: none; padding: 0; margin: 0; flex-grow: 1; white-space: nowrap;">
        ${stockDocs
          .filter((doc, index) => doc?.name?.split(".").pop() === "pdf")
          ?.map(
            (doc) => `
<li style="display: flex; align-items: center; padding: 10px 0; border-bottom: 1px solid rgba(255, 255, 255, 0.2); overflow-x: auto;">
  <a href="${
    process.env.REACT_APP_SPACE_LINK + "/" + doc.doc
  }" target="_blank" rel="noopener noreferrer">
    <div style="width: 30px; height: 30px; margin-right: 10px;">
      ${getDocumentSvg(doc.name)}
    </div>
  </a>
  <div>
    <span style="font-size: 16px; font-weight: bold;">${doc.name}</span>
  </div>
</li>

`
          )
          .join("")}
      </ul>
    </div>
      </div>
    </div>
  `;

    console.log("Generated Header HTML: ", headerHtml);

    // Generate dynamic elements added to the editor
    const elementsHtml = elements
      .map((element) => {
        const inlineStyles = `
        top: ${element.position.y}px;
        left: ${element.position.x}px;
        width: ${element.styles.width};
        height: ${element.styles.height};
        font-size: ${element.styles.fontSize};
        color: ${element.styles.color};
        text-align: ${element.styles.textAlign};
        background-color: ${element.styles.backgroundColor || "transparent"};
        font-weight: ${element.styles.fontWeight || "normal"};
        font-style: ${element.styles.fontStyle || "normal"};
        text-decoration: ${element.styles.textDecoration || "none"};
        border-radius: ${element.styles.borderRadius};
        border: ${element.styles.border};
        box-sizing: border-box;
        white-space: pre-wrap; 

      `;

        if (element.type === "img") {
          return `<div style="${inlineStyles}"><img src="${element.content.url}" alt="Image Element" style="width: 100%; height: 100%; box-sizing: border-box;" /></div>`;
        } else if (element.type === "video") {
          return `<div style="${inlineStyles}"><video controls style="width: 100%; height: 100%; box-sizing: border-box;"><source src="${element.content.url}" type="video/mp4" /></video></div>`;
        } else if (element.type === "p" || element.type === "h1") {
          return `<div style="${inlineStyles}"><${element.type} style="white-space: pre-wrap; box-sizing: border-box;">${element.content.text}</${element.type}></div>`;
        } else if (element.type === "ul" || element.type === "ol") {
          return `<div style="${inlineStyles}"><${
            element.type
          } style="box-sizing: border-box;">${element.content.items
            .map((item) => `<li>${item}</li>`)
            .join("")}</${element.type}></div>`;
        }
        return "";
      })
      .join("");
    const imageSliderStyles = `
    <style>
      .image-slider-container {
        margin: 20px auto;
        max-width: 800px;
      }
      
      #stock-image-slider {
        height: 400px;
      }
      
      #stock-image-slider .slick-slide {
        height: 400px;
      }
      
      #stock-image-slider .slick-slide img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      
      #stock-image-slider .slick-prev,
      #stock-image-slider .slick-next {
        z-index: 1;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transition: background-color 0.3s;
      }
      
      #stock-image-slider .slick-prev:hover,
      #stock-image-slider .slick-next:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
      
      #stock-image-slider .slick-prev:before,
      #stock-image-slider .slick-next:before {
        font-size: 24px;
        opacity: 1;
        color: white;
      }
      
      #stock-image-slider .slick-prev {
        left: 10px;
      }
      
      #stock-image-slider .slick-next {
        right: 10px;
      }
      
      #stock-image-slider .slick-dots {
        bottom: 10px;
      }
      
      #stock-image-slider .slick-dots li button:before {
        font-size: 12px;
        color: white;
        opacity: 0.7;
      }
      
      #stock-image-slider .slick-dots li.slick-active button:before {
        opacity: 1;
      }
    </style>
  `;
    const imageSliderHtml = `
    <div class="image-slider-container">
      <div id="stock-image-slider">
        ${images
          .map(
            (img, index) => `
          <div>
            <img src="${img}" alt="Slide ${index + 1}" />
          </div>
        `
          )
          .join("")}
      </div>
    </div>
  `;
    const slickInitScript = `
    <script>
      $(document).ready(function(){
        $('#stock-image-slider').slick({
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          adaptiveHeight: false,
          autoplay: true,
          autoplaySpeed: 3000
        });
      });
    </script>
  `;

    // Return full HTML content
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Generated HTML</title>
       ${
         images && images.length > 0
           ? `
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"/>
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"/>
      `
           : ""
       }
      <style>
     
        body {
          background-color: ${backgroundColor};
          font-family: Arial, sans-serif;
          margin: 0 auto;
          padding: 10px;
          max-width: 960px;
          box-sizing: border-box;
        }
        .slick-prev:before, .slick-next:before {
          color: black;
        }
      </style>
        ${imageSliderStyles}
    </head>
    <body>
      ${headerHtml}
       ${images && images.length > 0 ? imageSliderHtml : ""}
      ${elementsHtml}
    ${
      images && images.length > 0
        ? `
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
        ${slickInitScript}
      `
        : ""
    }
    </body>
      </html>
    `;
  };

  const exportHtmlFile = () => {
    const htmlContent = generateHtmlContent();
    const blob = new Blob([htmlContent], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "generated_page.html";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.editorContainer}>
      {stockTemplate && stockTemplate.length > 0 && !isUpdating ? (
        <>
          <div className={classes.previewArea}>
            <iframe
              title="HTML Preview"
              srcDoc={stockTemplate[0].template}
              className={classes.iframePreview}
            />
            <div className={classes.exportButtonContainer}>
              <Button
                onClick={handleUpdateTemplate}
                className={classes.exportButton}
                variant="contained"
              >
                Update Template
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <AppBar position="static" className={classes.toolbarContainer}>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              indicatorColor="primary"
              textColor="inherit"
            >
              <Tab label="Edit Mode" />
              <Tab label="Preview Mode" />
            </Tabs>
          </AppBar>

          {tabValue === 0 && (
            <>
              <ToolbarComponent
                handleAddElement={handleAddElement}
                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                selectedElementId={selectedElementId}
                getSelectedElement={getSelectedElement}
                handleStyleChange={handleStyleChange}
                handleDeleteElement={handleDeleteElement}
              />
              <HeaderComponent
                editable={true}
                headerContent={headerContent}
                onHeaderContentChange={handleHeaderContentChange}
                backgroundColor={backgroundColor}
                rowdata={rowData}
                stocks={stocks}
                stockCategories={stockCategories}
                stockOrderbook={stockOrderBook}
                stockDocs={stockDocs}
              />

              <ImageSlider
                images={images}
                onImageUpload={handleImageUpload}
                onImageDelete={handleDeleteImage}
                UploadDocs={UploadDocs}
                notify={notify}
              />
              <EditorArea
                elements={elements}
                selectedElementId={selectedElementId}
                renderElement={renderElement}
                backgroundColor={backgroundColor}
              />
              {!isUpdating && (
                <div className={classes.exportButtonContainer}>
                  <Button
                    onClick={handleCreateTemplate}
                    className={classes.exportButton}
                    variant="contained"
                  >
                    Create Template
                  </Button>
                </div>
              )}
            </>
          )}
          {tabValue === 1 && (
            <>
              {/* <HeaderComponent editable={false} headerContent={headerContent} /> */}

              <div className={classes.previewArea}>
                <iframe
                  title="HTML Preview"
                  srcDoc={generateHtmlContent()}
                  className={classes.iframePreview}
                />
                <div className={classes.exportButtonContainer}>
                  <Button
                    onClick={() => {
                      if (isUpdating) {
                        // Prepare the data object
                        const data = {
                          id: stockTemplate[0]?.id, // Using stockId from rowData
                          template: generateHtmlContent(), // The generated HTML content
                          isArchived: null, // As per your requirement
                        };

                        // Update the existing template
                        updateStockTemplate(data).then(() => {
                          setIsUpdating(false); // Reset the updating state
                          window.location.reload(); // Refresh the page after successful update
                        });
                        setIsUpdating(false); // Reset the updating state
                      } else {
                        exportHtmlFile();
                      }
                    }}
                    className={classes.exportButton}
                    variant="contained"
                  >
                    {isUpdating ? "Update New Template" : "Export to HTML"}
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const ToolbarComponent = ({
  handleAddElement,
  backgroundColor,
  setBackgroundColor,
  selectedElementId,
  getSelectedElement,
  handleStyleChange,
  handleDeleteElement,
}) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.toolbarContainer}>
      <Toolbar className={classes.toolbarOptions}>
        <Tooltip title="Add Heading">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.HEADING)}
          >
            <TitleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Paragraph">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.TEXT)}
          >
            <TextFieldsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Image">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.IMAGE)}
          >
            <ImageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Video">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.VIDEO)}
          >
            <VideoLibraryIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Document">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.DOCUMENT)}
          >
            <InsertDriveFileIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Link">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.LINK)}
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Bullet List">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.BULLET_LIST)}
          >
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Numbered List">
          <IconButton
            className={classes.toolbarButton}
            onClick={() => handleAddElement(ELEMENT_TYPES.NUMBERED_LIST)}
          >
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Change Background Color">
          <input
            type="color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
            className={classes.colorPicker}
          />
        </Tooltip>
        {selectedElementId && getSelectedElement() && (
          <>
            <Tooltip title="Bold">
              <IconButton
                className={
                  getSelectedElement().styles.fontWeight === "bold"
                    ? classes.toolbarButtonActive
                    : classes.toolbarButton
                }
                onClick={() =>
                  handleStyleChange(
                    "fontWeight",
                    getSelectedElement().styles.fontWeight === "bold"
                      ? "normal"
                      : "bold"
                  )
                }
              >
                <FormatBoldIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Italic">
              <IconButton
                className={
                  getSelectedElement().styles.fontStyle === "italic"
                    ? classes.toolbarButtonActive
                    : classes.toolbarButton
                }
                onClick={() =>
                  handleStyleChange(
                    "fontStyle",
                    getSelectedElement().styles.fontStyle === "italic"
                      ? "normal"
                      : "italic"
                  )
                }
              >
                <FormatItalicIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Underline">
              <IconButton
                className={
                  getSelectedElement().styles.textDecoration === "underline"
                    ? classes.toolbarButtonActive
                    : classes.toolbarButton
                }
                onClick={() =>
                  handleStyleChange(
                    "textDecoration",
                    getSelectedElement().styles.textDecoration === "underline"
                      ? "none"
                      : "underline"
                  )
                }
              >
                <FormatUnderlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Element Background Color">
              <input
                type="color"
                value={getSelectedElement().styles.backgroundColor}
                onChange={(e) =>
                  handleStyleChange("backgroundColor", e.target.value)
                }
                className={classes.colorPicker}
              />
            </Tooltip>
            <Tooltip title="Font Color">
              <input
                type="color"
                value={getSelectedElement().styles.color}
                onChange={(e) => handleStyleChange("color", e.target.value)}
                className={classes.colorPicker}
              />
            </Tooltip>
            <Input
              type="number"
              value={getSelectedElement().styles.fontSize.replace("px", "")}
              onChange={(e) =>
                handleStyleChange("fontSize", e.target.value + "px")
              }
              placeholder="Font Size"
              className={classes.input}
            />
            <FormControl className={classes.input}>
              <InputLabel>Text Alignment</InputLabel>
              <Select
                value={getSelectedElement().styles.textAlign}
                onChange={(e) => handleStyleChange("textAlign", e.target.value)}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Delete Element">
              <IconButton
                className={classes.toolbarButton}
                onClick={handleDeleteElement}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const EditorArea = ({
  elements,
  selectedElementId,
  renderElement,
  backgroundColor,
}) => {
  const classes = useStyles();
  const editorAreaRef = useRef(null);

  return (
    <div
      className={classes.editorArea}
      style={{ backgroundColor }}
      ref={editorAreaRef}
    >
      {elements.map((element) => renderElement(element))}
    </div>
  );
};

const mapStateToProps = ({ adminReducer }) => ({
  stockTemplate: adminReducer.stockTemplates,
  loadings: adminReducer.loadings,
  stocks: adminReducer.stocks,
  stockCategories: adminReducer.stockCategories, // Adjust based on your state structure
  stockOrderBook: adminReducer.stockOrderBook,
  stockDocs: adminReducer.stockDocs,
});

const mapDispatchToProps = (dispatch) => ({
  UploadDocs: (data, path) => dispatch(actionCreator.uploadDocs(data, path)),
  notify: (message, variant) =>
    dispatch(actionCreator.notify(message, variant)),
  getStockTemplate: (data) => dispatch(actionCreator.getStockTemplate(data)),
  createStockTemplate: (data) =>
    dispatch(actionCreator.createStockTemplate(data)),
  updateStockTemplate: (data) =>
    dispatch(actionCreator.updateStockTemplate(data)),
  getStock: (data) => dispatch(actionCreator.getStocks(data)),
  getStockCategories: (data) =>
    dispatch(actionCreator.getStockCategories(data)),
  getStockOrderBook: (data) => dispatch(actionCreator.getStockOrderBook(data)),
  getStockDoc: (data) => dispatch(actionCreator.getStockDoc(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateMakerPage);
